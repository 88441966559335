import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import axios from "axios"
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { directive as VNumber } from '@coders-tm/vue-number-format'
//import { toRaw } from 'vue';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "admin-lte"
import "bootstrap"
import "jquery"

const $toast = useToast();
const backend_data = window.backend_data

export const company_app = {
    mounted() {
        if (backend_data.action == "new") {
            axios.get("/companies/new.json").then(res => {
                var load = res.data
                delete load.created_at
                delete load.update_at
                delete load.nid
                delete load._id
                delete load.programs
                delete load.source
                load.user_id = backend_data.user_id
                load.source = "form_web"
                this.company = load
            })
        }
        if (backend_data.action == 'edit'){
            let currentUrl = window.location.href;
            let jsonUrl = currentUrl.replace("/edit", "") + ".json";
            axios.get(jsonUrl).then(res => {
                console.log('llega asi el json', res.data);
                var load = res.data
                var load = res.data
                delete load.created_at
                delete load.update_at
                delete load.nid
                delete load.programs
                delete load.source
                load.source = "form_web"
                this.company = load
                console.log('lo que traigo es esto ',load);
            })
        }
    },
    data() {
        return {
            company: {
                nit: null,
                representante_legal: { id_type: null },
                representante_legal_suplente: { id_type: null },
                ingresos: { a2023:''},
                ciiu: [],
                intereses_estandares: [],
                certificacion_actual: null,
                pais_interes: ['municipio'],
            },
            intereses_list: backend_data.intereses,
            formas_adquisicion_list: backend_data.formas_adquisicion,
            encuentra_certificada: { si: "Si", no: "No", enproceso: "En proceso" },
            buscadores: { municipios: { data: { results: [] } }, ciiu: { data: { results: [] } }, paises: { data: { results: [] } } },
            cop_config: { decimal: ',', separator: '.', prefix: 'COP ' },
            accept_terms: false,
            errors: { representante_legal: {} },
            required_fields: ['tipo_ubicacion','tipo_persona','direccion','municipio','razon_social','nit','telefono_celular','user_rol','tamano','colaboradores','ingresos','sector','ciiu','ventas_mensuales','valor_total'],
            representante_legal_required_fields:['id_type','nid','nombres','apellidos','email','cellphone','birthdate','gender','educational_level']
        }
    },
    methods: {
        buscadorciudades: function (search, loading) {
            loading(true);
            axios.get(`/findcity/?term=${search}`).then(res => {
                this.buscadores.municipios = res;
                loading(false)
            });
        },
        buscadorciiu: function (search, loading) {
            loading(true);
            axios.get(`/findciiu/?term=${search}`).then(res => {
                this.buscadores.ciiu = res;
                loading(false)
            });
        },
        buscadorpaises: function (search, loading) {
            loading(true);
            axios.get(`/findcountry/?term=${search}`).then(res => {
                this.buscadores.paises = res;
                loading(false)
            });
        },        
        validate_form: function () {
            let valid = true

            this.errors = { representante_legal: {} };

            if (!this.company.razon_social) {
                this.errors.razon_social = "Razon Social es requerido";
            }

            if (!this.company.tipo_persona) {
                this.errors.tipo_persona = "Tipo de persona es requerido";
            }
            if (!this.company.ventas_mensuales) {
                this.errors.ventas_mensuales = "las ventas_mensuales es requerido";
            }
            if (!this.company.valor_total) {
                this.errors.valor_total = "El valor total de los activos es requerido";
            }
            if (!this.company.nit) {
                this.errors.nit = "NIT es requerido";
            }
            if (this.company.tipo_persona === 'juridica') {
                if (!/^[89]\d{8}$/.test(this.company.nit)) {
                    this.errors.nit = 'NIT debe tener 9 dígitos y empezar con 8 o 9 para persona jurídica.';
                }
            } else if (this.company.tipo_persona) { // Validar solo si hay un tipo de persona seleccionado
                if (![6, 7, 8, 9, 10].includes(this.company.nit.length)) {
                    this.errors.nit = 'NIT debe tener 6, 7, 8, 9 o 10 dígitos para personas naturales.';
                }
            }
            if (!this.company.direccion) {
                this.errors.direccion = "Dirección es requerido";
            }
            if (!this.company.tipo_ubicacion) {
                this.errors.tipo_ubicacion = "El tipo de ubicación es requerido";
            }
            if (!this.company.municipio) {
                this.errors.municipio = "EL municipio es requerido";
            }
            if (!this.company.tamano) {
                this.errors.tamano = "EL tamaño es requerido";
            }
            if (!this.company.user_rol) {
                this.errors.user_rol = "EL rol/cargo es requerido";
            }
            if (this.company.ciiu.length == 0) {
                this.errors.ciiu = "La/Las actividades CIIU son requeridas";
            } 
            if (!this.company.sector) {
                this.errors.sector = "EL sector es requerido";
            }
            const phonePattern = /^([0-9]{10}$)/

            if (!this.company.telefono_celular) {
                this.errors.telefono_celular = 'El número de celular es obligatorio.';
                valid = false;
            } else if (!phonePattern.test(this.company.telefono_celular)) {
                this.errors.telefono_celular = 'Número de celular inválido.';
                valid = false;
            }
            
            if (!this.company.representante_legal.cellphone) {
                this.errors.representante_legal = { ...this.errors.representante_legal, cellphone: "El número de celular del Representante legal es obligatorio" };
                valid = false;
            } else if (!phonePattern.test(this.company.representante_legal.cellphone)) {
                this.errors.representante_legal = { ...this.errors.representante_legal, cellphone: "Número de celular inválido." };
                valid = false;
            }
            if (!this.company.ingresos.a2023) {
                this.errors.ingresos = "Los ingresos son obligatorios";
                valid = false;
            }     
            if (!this.company.representante_legal.educational_level) {
                this.errors.representante_legal = { ...this.errors.representante_legal, educational_level: "El nivel educativo es requerido" };
            }
            if (!this.company.representante_legal.id_type) {
                this.errors.representante_legal = { ...this.errors.representante_legal, id_type: "Tipo ID del Representante Legal es requerido" };
            }
            if (!this.company.representante_legal.birthdate) {
                this.errors.representante_legal = { ...this.errors.representante_legal, birthdate: "La fecha de nacimiento es obligatoria" };
            } else {
                const isValidAge = this.validate_age(this.company.representante_legal.birthdate);
                if (!isValidAge) {
                  this.errors.representante_legal.birthdate = "El representante legal debe tener más de 18 años";
                }
            }
            if (!this.company.representante_legal.gender) {
                this.errors.representante_legal = { ...this.errors.representante_legal, gender: "El género es obligatorio" };
            }
            if (!this.company.representante_legal.nid) {
                this.errors.representante_legal = { ...this.errors.representante_legal, nid: "Numero de ID del Representante Legal es requerido" };
            }
            if (!this.company.representante_legal.nombres) {
                this.errors.representante_legal = { ...this.errors.representante_legal, nombres: "Nombres del Representante Legal es requerido" };
            }
            if (!this.company.representante_legal.apellidos) {
                this.errors.representante_legal = { ...this.errors.representante_legal, apellidos: "Apellidos del Representante Legal es requerido" };
            }
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!this.company.representante_legal.email) {
                this.errors.representante_legal = { ...this.errors.representante_legal, email: "El correo del Representante Legal es requerido" };
                valid = false;
            } else if (!emailPattern.test(this.company.representante_legal.email)) {
                this.errors.representante_legal = { ...this.errors.representante_legal, email: "Formato de correo electrónico inválido." };
                valid = false;
            }

            if (!this.company.colaboradores) {
                this.errors.colaboradores = 'El número de colaboradores es obligatorio.';
                valid = false;
            }
            if (Object.keys(this.errors).length > 1) {
                return false;
            } else if (Object.keys(this.errors.representante_legal).length > 0) {
                return false;
            }
            return true;
        },
        next_validate: function () {
            if (this.validate_form()) {
                $('#modal-default').modal("show");
            }
        },
        send_form: function () {
            if (backend_data.action == 'new'){
                this.create_company()
            }
            if (backend_data.action == 'edit'){
                this.update_company()
            }
        },
        create_company: function (){
            let load = this.company;
            if (typeof load.municipio === "object") {
                load.municipio = load.municipio.id;
            }
            axios.post("/companies.json", { company: this.company, authenticity_token: backend_data.authenticity_token }).then(res => {
                window.location.replace(`/companies/${res.data.id}`);
            }).catch(errors => {
                for (let error in errors.response.data) {
                    for (let e of errors.response.data[error])
                        $toast.error(error + ": " + e, { position: "top-right", duration: 5000 });
                }
            })
        },
        update_company: function () {
            if (typeof this.company.municipio === "object") {
                this.company.municipio = this.company.municipio.id;
            }
            axios.put(`/companies/${this.company.id}.json`,{ company: this.company, authenticity_token: backend_data.authenticity_token }).then(res => {
                window.location.replace(`/companies/${res.data.id}`);
            }).catch(errors => {
                console.log('esto trajo', errors);
            })

        },
        nit_calc: function (myNit) {
            var vpri, x, y, z;
            if (isNaN(myNit) || myNit == null || myNit == undefined) { return "NA"; };
            myNit = myNit.replace(/\s/g, "");
            myNit = myNit.replace(/,/g, "");
            myNit = myNit.replace(/\./g, "");
            myNit = myNit.replace(/-/g, "");
            vpri = new Array(16);
            z = myNit.length;
            vpri[1] = 3; vpri[2] = 7; vpri[3] = 13;
            vpri[4] = 17; vpri[5] = 19; vpri[6] = 23;
            vpri[7] = 29; vpri[8] = 37; vpri[9] = 41;
            vpri[10] = 43; vpri[11] = 47; vpri[12] = 53;
            vpri[13] = 59; vpri[14] = 67; vpri[15] = 71;
            x = 0;
            y = 0;
            for (var i = 0; i < z; i++) {
                y = (myNit.substr(i, 1));
                x += (y * vpri[z - i]);
            }
            y = x % 11;
            return (y > 1) ? 11 - y : y;
        },
        sanitizeAndValidateNIT() {
            this.company.nit = this.company.nit.replace(/\D/g, '');
        },
        updateVentasMensuales(event) {
            this.company.ventas_mensuales = event.target.value.replace(/[^0-9]/g, '');
        },
        updateValorTotal(event) {
            this.company.valor_total = event.target.value.replace(/[^0-9]/g, '');
        },
        updateIngresos2023(event) {
            this.company.ingresos.a2023 = event.target.value.replace(/[^0-9]/g, '');
        },
        formatCurrency(value) {
            if (!value) return 'COP ';
            const numericValue = value.replace(/[^0-9]/g, '');
            return 'COP ' + numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        currencyPlaceholder(value) {
            if (!value) {
                return 'COP 0.000';
            }
            return `COP ${this.formatCurrency(value)}`;
        },
        validate_age: function (birthdate) {
            const today = new Date();
            const birthDate = new Date(birthdate);
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }
            return age >= 18;
          }
    },
    computed: {
        formattedVentasMensuales() {
            return this.formatCurrency(this.company.ventas_mensuales);
        },
        formattedValorTotal() {
            return this.formatCurrency(this.company.valor_total);
        },
        formattedIngresos2023() {
            return this.formatCurrency(this.company.ingresos.a2023);
        }
    },
    components: {
        'v-select': vSelect,
        'vue-number': VueNumber
    },
    directives: {
        'number': VNumber,
    }
}
export default company_app;
